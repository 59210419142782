.popupAviso {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.popupAviso-innerAviso {
    position: absolute;
    padding: 20px 20px 20px 20px;
    width: 80%;
    height: 300px;
    max-width: 90%;
    background-color: #ffffff;
    box-shadow: 0 0 5px grey;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: black;
}

.popupAviso-innerAviso .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
}
