:root {
    --color-white: #fff;
    --color-black: #333;
    --color-gray: #75787b;
    --color-gray-light: #bbb;
    --color-gray-disabled: #e8e8e8;
    --color-blue: #00d1e6;
    --font-size-small: 0.5rem;
    --font-size-default: 0.6rem;
}

* {
    box-sizing: border-box;
}

.progressBar {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-top: 10px;
    width: 100%;
}
.progressBar li {
    flex: 2;
    position: relative;
    padding: 0 0 14px 0;
    font-size: var(--font-size-default);
    line-height: 2;
    color: var(--color-blue);
    font-weight: 600;
    white-space: nowrap;
    overflow: visible;
    min-width: 0;
    text-align: center;
    border-bottom: 2px solid var(--color-gray-disabled);
}
.progressBar li:first-child {
    flex: 1;
    text-align: left;
}
.progressBar li:last-child {
    flex: 1;
    text-align: right;
    z-index: 0;
}
.progressBar li:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: var(--color-gray-disabled);
    border-radius: 50%;
    border: 2px solid var(--color-white);
    position: absolute;
    left: calc(50% - 6px);
    bottom: -6px;
    z-index: 3;
    transition: all 0.2s ease-in-out;
}
.progressBar li:first-child:before {
    left: 0;
}
.progressBar li:last-child:before {
    right: 0;
    left: auto;
}
.progressBar span {
    transition: opacity 0.3s ease-in-out;
    z-index: -1;
}
.progressBar li:not(.isActive) span {
    opacity: 0;
}
.progressBar .is-complete:not(:first-child):after,
.progressBar .isActive:not(:first-child):after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    bottom: -2px;
    left: -50%;
    z-index: 2;
    border-bottom: 2px solid var(--color-blue);
}
.progressBar li:last-child span {
    width: 200%;
    display: inline-block;
    position: absolute;
    left: -100%;
}

.progressBar .is-complete:last-child:after,
.progressBar .isActive:last-child:after {
    width: 200%;
    left: -100%;
}

.progressBar .is-complete:before {
    background-color: var(--color-blue);
}

.progressBar .isActive:before,
.progressBar li:hover:before,
.progressBar .is-hovered:before {
    background-color: var(--color-white);
    border-color: var(--color-blue);
}
.progressBar li:hover:before,
.progressBar .is-hovered:before {
    transform: scale(1.33);
}

.progressBar li:hover span,
.progressBar li.is-hovered span {
    opacity: 1;
}

.progressBar:hover li:not(:hover) span {
    opacity: 0;
}

.x-ray .progressBar,
.x-ray .progressBar li {
    border: 1px dashed red;
}

.progressBar .has-changes {
    opacity: 1 !important;
}
.progressBar .has-changes:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    left: calc(50% - 4px);
    bottom: -20px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%208%208%22%3E%3Cpath%20fill%3D%22%23ed1c24%22%20d%3D%22M4%200l4%208H0z%22%2F%3E%3C%2Fsvg%3E");
}
